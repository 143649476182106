.ag-theme-alpine {
  --ag-background-color: #ffffff;
  --ag-odd-row-background-color: #ffffff;
  --ag-header-background-color: rgb(245, 245, 245);
  max-height: none !important;
}

.table-header {
  padding: 16px 16px 0px 16px !important;
}

.ag-header-cell-text {
  font-family: "Inter";
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #202020;
}

.search {
  margin-left: 82.5%;
}

.actionCol {
  overflow: visible !important;
}

.ag-row {
  z-index: 0;
  border-bottom: 1px solid #e4e3e3;
  cursor: pointer;
}

.ag-row-focus {
  z-index: 999;
}

.ag-root-wrapper {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
}

.ag-header {
  border: none;
  border-bottom: 1px solid #e4e3e3;
  height: 40px !important;
  min-height: 40px !important;
}

.ag-header-row {
  height: 40px !important;
  min-height: 40px !important;
}

.ag-header-cell-label {
  color: var(--secondary-font-color);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}

.ag-cell {
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.ag-cell-img {
  border-radius: 5px;
  margin-right: 16px;
}

.card {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  border: none;
}

.status-badge {
  border-radius: 36px;
  height: 24px;
  width: 84px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  padding-top: 6px;
}

.data-grid {
  height: auto !important;
}

.ag-root {
  min-width: 900px;
}

.ag-root-wrapper-body {
  overflow-x: auto;
}
::-webkit-scrollbar {
  height: 4px;
  border: 1px solid #d5d5d5;
}

.grid-pagination {
  margin-right: 24px;
  margin: inherit;
}

.page-link {
  background: var(--table-header-background) !important;
  border-width: 1px;
  border-style: solid;
  border-color: #3d3d3d !important;
  border-radius: 4px 0px 0px 4px;
  color: var(--secondary-font-color) !important;
}

.active > .page-link {
  border: 1px solid var(--pagination-active-color) !important;
  color: var(--pagination-active-color) !important;
  border-radius: 0px;
}

.page-link:focus {
  box-shadow: none;
  border-radius: 0px;
  color: #6b7280;
}

.ag-row-hover {
  background-color: #f7f7f7;
}

.form-control:focus {
  box-shadow: none;
}

.input-group-text {
  background-color: white;
  border: 1px solid #e9e8ee;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.page-link:focus {
  z-index: 1;
}

.ag-paging-panel {
  align-items: center;
  border-top: var(--ag-footer-row-border);
  display: flex;
  gap: calc(var(--ag-spacing) * 4);
  height: max(var(--ag-row-height), 22px);
  justify-content: flex-end;
  padding: 0 var(--ag-cell-horizontal-padding);
}

@media (max-width: 768px) {
  .ag-paging-page-size {
    display: none;
  }
  .ag-paging-row-summary-panel {
    display: none;
  }
}

.pagination {
  padding-right: 24px;
  margin-top: 11px !important;
}

.pagination-title {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-font-color);
}

.pagination-section {
  padding: 11px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 10px;
  -webkit-box-shadow: inset 0 2px 2px #f3f3f3;
  -moz-box-shadow: inset 0 2px 2px #f3f3f3;
  box-shadow: inset 0 2px 2px #f3f3f3;
}

.data-table-list-container {
  padding-bottom: 30px;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
