.delete--user-modal .modal-header,
.delete--modal-warning .modal-header {
  justify-content: center;
}

.delete--modal-warning .btn-primary {
  display: none;
}

.delete--modal-warning .btn-white {
  margin-right: 0;
}

.delete--modal-warning .edit--modal-footer,
.delete--user-modal .edit--modal-footer {
  padding: 16px 24px;
}

.edit--role-modal .modal-header,
.edit--role-modal .modal-body {
  padding-left: 64px;
  padding-right: 64px;
}

.delete--user-modal .modal-body {
  padding-top: 0;
  padding-bottom: 58px;
}

.delete--user-modal .modal-body p {
  margin-bottom: 0;
}

.delete--user-modal .modal-dialog,
.delete--modal-warning .modal-dialog {
  max-width: 401px;
}

.edit--role-modal .modal-dialog {
  max-width: 1000px;
}

.edit--role-modal input[type="text"] {
  height: 38px;
}

.edit--role-modal input:focus {
  border: 1px solid #ced4da;
}

.edit--role-form > div {
  padding-bottom: 24px;
}

.delete--modal-warning .modal-body {
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  padding-top: 0;
}
