.bg-primary {
  background-color: #b8ffb1 !important;
  color: #065f46;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.dropdown-icon-margin {
  margin-right: 10px;
}
