.modal-dialog .rmsc .dropdown-content {
  position: relative;
  margin-top: 4px;
  padding-top: 0;
}

.modal-dialog .rmsc .select-panel {
  padding-left: 13px;
  padding-right: 13px;
}

.modal-dialog .dropdown-content .search {
  width: auto;
  margin-left: 0;
  margin: 13px 0;
  border-radius: 6px;
  background-color: #ffffff !important;
  border: 1px solid #e9e8ee;
  display: flex;
  opacity: 1;
}

.modal-dialog .dropdown-content .search::before {
  content: "";
  background-image: url("/public/search-icon.svg");
  background-size: 16px;
  display: inline-flex;
  height: 16px;
  width: 16px;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 10px;
}

.modal-dialog .rmsc .search input {
  background-color: #ffffff !important;
}

.modal-dialog .rmsc .dropdown-heading,
.modal-dialog .rmsc .search input,
.modal-dialog .rmsc .select-item {
  border-radius: 6px;
}

.modal-dialog .rmsc .select-item.selected,
.modal-dialog .rmsc .select-item:hover {
  background-color: #f4f3ff !important;
}

.modal-dialog .rmsc .dropdown-heading {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.modal-dialog .rmsc .dropdown-container:focus-within {
  border: none;
  box-shadow: none;
}

.modal-dialog .rmsc .item-renderer {
  align-items: center;
}

.modal-dialog .rmsc .dropdown-container {
  border: none;
}
