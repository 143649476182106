.loginHeader {
  text-align: center;
  margin-bottom: 48px;
}

.forgot-password h3.login-heading {
  margin-bottom: 12px;
}

.forgot-password p {
  color: rgba(32, 32, 32, 0.7);
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
}

.email-field input[type="email"] {
  border-radius: 6px;
  height: 42px;
  margin-top: 40px;
  border: 1px solid #E9E9E9 !important;
  padding-left: 13px;
  border-radius: 6px;
  background-color: #ffffff !important;
  color: #202020 !important;
}

.email-field input[type="email"]::placeholder {
  color: rgba(32, 32, 32, 0.3);
}


.forgotPassHeader {
  max-width: 350px;
}

.forgot-password .submit-btn::before {
  display: none;
}

.forgot-password .submit-btn {
  margin-bottom: 0;
  background-color: #14BF59;
}
