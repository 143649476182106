.article-refresh-button {
  margin-top: 124px;
  width: auto;
}
.permission-text-container {
  height: 36px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff99;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.drop-down-permission-img {
  margin-left: 20px;
}
.permission-tag {
  background-color: #F2F2F2;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 2px;
  padding: 5px 10px 5px 10px;
  border-radius: 56px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  color: #202020;
}
.category-text-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}
.artical-text-main-container {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
  background-color: rgb(250, 250, 250);
  border-bottom: 1px solid #e9e9e9;
}
.artical-dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add-artical-container {
  height: 36px;
  border-radius: 8px;
  background-color: #14bf59;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.add-artical-text-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  margin-left: 10px;
}
.menu-icon-container-style {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.article-search .search-input {
  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  height: auto;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 44px;
}

.article-search form {
  width: 50%;
  margin-right: 0px;
  height: 56px;
}

.search-icon {
  margin-left: 16px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: var(--secondary-font-color);
  font-weight: bold;
}

.articles-section {
  margin-top: 64px;
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;
}

.recent-articles {
  margin-top: 32px;
}

.header-title {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: var(--primary-font-color);
}

.filter-dropdown {
  padding-left: 0px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.view-all {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f46e5;
  cursor: pointer;
}

.article-page-title {
  margin-right: 0px;
  padding-bottom: 8px;
}

.view-all {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #4f46e5;
  cursor: pointer;
}

.table-title h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--primary-font-color);
}

.table-title h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-font-color);
}

.table-container {
  background-color: var(--main-container-bg-color);
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  margin-bottom: 5%;
  margin-top: 62px;
  padding-right: 0px;
  padding-left: 0px;
}

.table-add-button .dropdown-toggle::before {
  display: inline-block;
  margin-right: 1em;
  vertical-align: middle;
  content: "";
  background-image: url("/public/plus.svg");
  background-repeat: no-repeat;
  background-position: left;
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
}

.table-add-button .dropdown-toggle::after {
  display: none !important;
}

.article-page-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
}

.table-add-button .btn-primary {
  padding: 9px 17px 9px 15px;
  width: 96px;
  height: 42px;
  background: #4f46e5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  border: none;
}

.css-1tspqtw-InputComponent {
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  color: #6b7280;
}

.drop-down-container-status {
  padding: 12px;
  border-radius: 8px;
  background-color: white;
}

.drop-down-status-item {
  width: 206px;
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222222;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.apply-and-cancle-container {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f2f2f2;
  justify-content: space-between;
}
.cancle-text-container {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #000000;
  background-color: #f2f2f2;
  width: 90px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
}
.apply-text-container {
  margin-top: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  background-color: #14bf59;
  width: 90px;
  height: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.more-text-in-dropdown {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #14bf59;
  margin-left: 5px;
}

.checkbox-icon {
  margin-right: 6px;
}

@media screen and (max-width: 992px) {
  .article-search form {
    width: 100%;
  }
  .article-page-container {
    width: 95%;
  }
  .table-title {
    padding-left: 0px;
  }
  .table-container {
    width: 95%;
  }
}
