#packages .table-container {
  background-color: var(--main-container-bg-color);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  margin-bottom: 5%;
  margin-top: 62px;
  padding-right: 0px;
  padding-left: 0px;
}
