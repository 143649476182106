.add-user-input-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
  color: #000000;
  padding: 10px;
  width: 100%;
  border: 1px solid #e9e9e9;
  padding-left: 13px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.password-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e9e9e9;
  padding: 10px;
  width: 100%;
  margin-top: 8px;
  padding-left: 13px;
  border-radius: 6px;
}
.password-input-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #000000;
  outline: none;
  border: none;
}

.role-indicator {
  width: 10px;
  height: 10px;
  margin-right: 8px;
  border-radius: 12px;
}
.create-password-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #202020;
  margin-bottom: 20px;
}
.user-name-text-view {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
  margin-top: 10px;
}
.bottom-border-line {
  margin-top: 25px;
  margin-bottom: 20px;
  border: 0.5px solid #f1f1f1;
}
