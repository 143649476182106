.user-name {
  color: var(--primary-font-color);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 0px;
}

.user-role {
  padding: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #6b7280;
}

.menu-toolbar {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-family: "Inter";
}

.top-menu {
  background: var(--top-menu-background);
  border-bottom: 1px solid var(--top-menu-background);
  height: 64px;
  padding-right: 0px;
  padding-left: 0px;
}

.user-info {
  display: flex;
  flex-direction: column !important;
  text-align: right;
}

.nave-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--secondary-font-color);
}

.nav-link {
  color: var(--secondary-font-color);
}

.nav-link:hover {
  color: var(--primary-font-color);
}

.nav-link:active {
  color: var(--primary-button-color) !important;
}

.nav-space {
  margin-left: 20px;
}

.search-form {
  margin-right: 40px;
  width: 20px;
  transition: all 0.3s;
}

.search-form.show {
  width: 400px;
}

.search-form .glass-icon {
  left: 14px;
  cursor: pointer;
  position: absolute;
  scale: 1.1;
  transition: all 0.3s;
}

.search-form.show .glass-icon {
  scale: 1;
}

.search-icon {
  color: var(--secondary-font-color);
  position: absolute;
  margin-left: 10px;
}

.search-input {
  padding-left: 40px;
  background: var(--top-search-input-color);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 36px;
  margin-right: 0px !important;
  border: none;
}

.search-form.show .search-input {
  border: 1px solid #e9e8ee;
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: var(--dropdown-item-hover) !important;
  background: var(--dropdown-menu-background);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  padding: 8px;
}

.dropdown-item:hover {
  background: var(--dropdown-item-hover);
  border-radius: 6px !important;
}

.dropdown-item {
  padding: 10px 10px;
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--primary-font-color);
}

.dropdown-item:active {
  background-color: var(--dropdown-item-active) !important;
}

.dropdown-sub-menu-item {
  width: 245px;
}

.dropdown-sub-menu-description {
  width: 100%;
  white-space: break-spaces;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-font-color);
  margin-top: 4px;
}

.dropdown-sub-menu-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-font-color);
  margin-top: -3px;
}

.dropdown-menu-icon {
  margin-right: 10px;
  display: flex;
}

.navbar-nav .dropdown-menu {
  padding: 16px;
}

#imag-navbar ~ .dropdown-menu {
  padding: 0px !important;
}

.propertie-navbar {
  background: var(--table-menu-font-color);
  padding: 6px 10px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.nav-active.nav-space.nav-link:hover {
  color: var(--primary-button-color);
}

.nav-link:focus,
.nav-link:hover {
  color: var(--primary-button-color);
}

@media screen and (max-width: 992px) {
  .menu-toolbar {
    width: 95%;
  }

  .navbar-collapse {
    z-index: 2;
    background: var(--dropdown-menu-background);
    box-shadow: 0px 4px 24px rgb(0 0 0 / 16%);
    border-radius: 6px;
    padding: 10px;
    width: 100%;
  }

  .search-form.show {
    width: 100%;
    margin-top: 5px;
  }

  .userInfo {
    margin-right: 10px;
  }

  .nav-space {
    margin-left: 0px;
  }
}
