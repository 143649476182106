.table-title h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: var(--primary-font-color);
}

.table-title h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-font-color);
}

.table-container {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5%;
  margin-bottom: 5%;
  margin-top: 62px;
  padding-right: 0px;
  padding-left: 0px;
}

.reservation-list-container {
  background-color: white;
  padding: 0px;
  width: 100%;
  height: 100vh;
  position: relative;
}
