.mt-48px {
  margin-top: 48px;
}

.mt-24px {
  margin-top: 24px;
}

.mt-16px {
  margin-top: 16px;
}

.mb-17px {
  margin-bottom: 4px;
}

.mt-14px {
  margin-top: 14px;
}

.mt-10px {
  margin-top: 10px;
}

.mt-12px {
  margin-top: 12px;
}

.check-box-group .form-check {
  margin-top: 12px;
}

.file-change-btn {
  background-color: var(--modal-background-color);
  color: var(--primary-font-color);
  border-color: #d1d5db;
}


.form-check-input~.form-check-label {
  color: #747474 !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 255, 0, 0.25);
}

/* set active green color for checkbox */
.form-check-input:checked {
  background-color: rgb(18, 141, 71) !important;
  border-color: rgb(18, 141, 71) !important;
}

.form-check-input:checked {
  background-color: rgb(18, 141, 71) !important;
  border-color: rgb(18, 141, 71) !important;
}

.form-check-input:checked~.form-check-label {
  color: #000000 !important;
}


.ReactTags__tags {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 8px;
  background-color: #FFFFFF;
}

.ReactTags__tag {
  background-color: rgba(26, 195, 99, 0.3);
  color: rgb(18, 141, 71);
  border-radius: 16px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-left: 1px solid rgba(26, 195, 99, 0.3);
}

.ReactTags__remove {
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
  color: rgb(18, 141, 71);
  font-size: 20px;
  border: none;
  font-weight: bold;
  background: none;
  padding: 0;
  display: flex;
  align-items: center;
  order: -1;
}

.ReactTags__tagText {
  margin-left: 5px;
}

.ReactTags__tagInputField {
  border: none;
  outline: none;
  margin: 4px 0;
  padding: 5px;
  font-size: 14px;
  width: auto;
}

.ReactTags__suggestions {
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ReactTags__suggestion {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.ReactTags__suggestion--active {
  background-color: rgba(26, 195, 99, 0.3);
  ;
  cursor: pointer;
}

.ReactTags__suggestion:hover {
  background-color: #e2e6ea;
  cursor: pointer;
}


.ReactTags__suggestions {
  position: absolute;

  ul {
    list-style-type: none;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
    padding: 0;
    margin: 0;
    border-radius: 4px;
  }

  li {
    padding: 5px 10px;
    margin: 0;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid #ced4da;

    &:last-child {
      border-bottom: none;
    }

    mark {
      text-decoration: none;
      background: none;
      color: inherit;
      padding: 0;
      font-weight: bold;
    }

    &.ReactTags__activeSuggestion {
      background: rgba(26, 195, 99, 0.3);;
      color: white;
      cursor: pointer;

      mark {
        color: white;
      }
    }
  }
}