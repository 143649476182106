.modal-delete-view-style .modal-content {
  background-color: white;
  color: black;
}

.modal-delete-view-style .modal-body {
  background-color: white;
  color: black;
  border-bottom: 0px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.delete-info-text-style {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #202020;
  margin-top: 32px;
  max-width: 250px;
}
.delete-info-image-style {
  margin-top: 40px;
}
.delete-info-remove-text-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #202020;
  padding: 0px 40px 0px 40px;
  margin-top: 10px;
}
.delete-info-btn-detele-style {
  width: 132px;
  height: 40px;
  border-radius: 8px;
  background-color: #db453c;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}
.delete-btn-cancal-style {
  width: 132px;
  height: 40px;
  border-radius: 8px;
  background-color: #f2f2f2;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.delete-btn-container {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
