.article-search-form {
  margin-right: 40px;
  width: 400px;
  transition: all 0.3s;
}

.article-search-form .glass-icon {
  left: 14px;
  cursor: pointer;
  position: absolute;
  scale: 1;
  transition: all 0.3s;
}

.article-search-form .search-input {
  border: 1px solid #e9e8ee;
}

.article-search-input {
  padding-left: 40px;
  background: var(--top-search-input-color);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 36px;
  margin-right: 0px !important;
  border: 1px solid var(--primary-font-color) !important;
}

.table-search {
  background: var(--text-input-bg-color);

  border-radius: 6px;
}

.table-search .input-group-text {
  border: none !important;
  padding-right: 10px;
  padding-left: 15px;
}

.table-search .form-control {
  border: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--secondary-font-color);
  padding: 8px 8px 8px 0px;
  margin-left: 50px;
}

.table-search .search-icon {
  width: 14px;
  height: 14px;
}

.inbox-search {
  background: #F0F0F0;
  border-radius: 6px;
}

.inbox-search .input-group-text {
  border: none !important;
  padding-right: 10px;
  padding-left: 15px;
}

.inbox-search .form-control {
  border: none !important;
  font-style: normal;
  background: #F0F0F0 !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: black !important;
  padding: 8px 0px 8px 0px;
  margin-left: 35px;
}

.inbox-search .search-icon {
  width: 14px;
  height: 14px;
}
