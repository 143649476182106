.stay-detail-container {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  padding: 30px 25px;
}

.hotel-image {
  width: 60px;
  height: 60px;
  border-radius: 58px;
}

.hotel-detail-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.hotel-name-container {
  margin-left: 10px;
}

.hotel-name {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #202020;
}

.no-room-text {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #ffa500;
}

.hotel-details {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
  margin-top: 8px;
}

.hotel-information-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
}

.hotel-information-title-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.hotel-info-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #747474;
}

.hotel-info-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.devider-previous-hotel-stay {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 1px;
  background-color: #f1f1f1;
}

.price-reservation-container-previous-stay {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  position: relative;
  margin-top: 20px;
  padding: 20px;
}

.devider-hotel-stay {
  margin-top: 40px;
  margin-bottom: 50px;
  height: 1px;
  background-color: #f1f1f1;
}

.hotel-section-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}

.room-list-container {
  margin-top: 20px;
  margin-bottom: 30px;
}

.room-list-item-main-container {
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  margin-bottom: 15px;
}

.room-list-item-container-closed {
  border-radius: 10px 10px 10px 10px;
}

.room-list-item-container {
  background-color: #fbfbfb;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

.text-info-container {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.special-request-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
  margin-left: 20px;
}

.hotel-section-title1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  margin-top: 40px;
}

.reservation-note-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
}

.bg-red {
  background-color: #f1f1f1;
  color: #202020;
}

.bdr {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e9e9e9;
}

.last-row-border-hide {
  border: 1px solid transparent;
}

.table {
  border-collapse: collapse;
  border-radius: 6px;
  border-style: hidden;
  margin-bottom: 0px;
}

.package-table-container {
  margin-top: 20px;
}

.star-container {
  height: 74px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  display: flex;
  width: 314px;
  flex-direction: row;
  padding: 20px;
}
.previous-stay-title {
  font-size: 12px;
  color: #202020;
}

.rating-style {
  font-size: 25px;
  color: #e9e9e9;
  align-self: center;
}
.vertical-line-divider {
  width: 1px;
  height: 28px;
  background-color: #e9e9e9;
  margin-left: 20px;
  margin-right: 15px;
}

.guest-rating-conatiner {
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.guest-rate-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
  opacity: 0.5;
  margin-left: 30px;
}

.table-header-title {
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  color: #202020;
  text-transform: uppercase;
}

.table-cell-package-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
  padding-top: 6px;
}

.table-cell-text-package-desc {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
  padding-bottom: 6px;
}

.table-cell-text {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}

.table-cell-price-text {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}

.package-table-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.confirm-button-package {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
  width: 70px;
  padding: 6px 16px 6px 16px;
  border-radius: 5px;
  background-color: #14bf59;
  cursor: pointer;
}

.deny-button-package {
  background-color: #db453c;
  margin-left: 5px;
}

.approved-button-package {
  width: min-content;
  padding: 6px 16px 6px 16px;
  border-radius: 5px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #0ca925;
  background-color: #eef8f0;
  cursor: pointer;
}

.denied-button-package {
  background-color: #fef6f6;
  color: #db453c;
  padding: 6px 22px 6px 22px;
}

.package-add-on-button-td {
  width: 20%;
  background-color: #000000;
}

.table-padding-left {
  padding-left: 5px;
}

.room-lock-info-container {
  border-top: 1px solid #f1f1f1;
}

.lock-list-item-container {
  padding: 20px;
}

.lock-name-title-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #747474;
}

.lock-name-title-value {
  margin-top: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.lock-list-row-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lock-unlock-button {
  border-radius: 8px;
  padding: 10px 0px 10px 0px;
  background-color: #db453c;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #ffffff;
  width: 142px;
  justify-content: center;
  cursor: pointer;
}

.lock-item-divider {
  width: 97%;
  height: 1px;
  background-color: #f1f1f1;
  margin-left: 3%;
}

.full-line-divider {
  width: 100%;
  height: 1px;
  background-color: #f1f1f1;
}

.door-access-code-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000;
  opacity: 0.8;
}

.access-code-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.no-reservation-note-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #202020;
  opacity: 0.5;
  width: 100%;
  padding: 34.5px;
}

.empty-package-table {
  display: flex;
  justify-content: center;
  align-content: center;
}

.rr--group {
  display: flex;
  width: 100%;
  position: relative;
}
.rr--box {
  display: flex;
  width: 100%;
  flex-grow: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rr--svg {
  display: flex;
  aspect-ratio: 1;
  width: 100%;
  flex-grow: 1;
  overflow: clip;
  pointer-events: none;
}
@supports not (overflow: clip) {
  .rr--svg {
    overflow: auto;
  }
}
.rr--box:focus,
.rr--box:focus-visible,
.rr-reset:focus-visible,
.rr-reset:focus {
  outline: none;
  box-shadow: none;
}
.rr--focus-reset {
  outline: 6px double #0079ff;
}
.rr--box:focus-visible .rr--svg {
  outline: 6px double #0079ff;
  isolation: isolate;
}
.rr--reset {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  right: 0;
  bottom: 50%;
}
[dir="rtl"] .rr--reset {
  left: 0;
  right: auto;
}
.rr--dir-y .rr--reset {
  bottom: 0;
  right: 50%;
}
.rr--disabled {
  cursor: not-allowed;
}
.rr--disabled .rr--svg {
  pointer-events: none;
}
.rr--pointer .rr--box {
  cursor: pointer;
}
.rr--dir-x {
  flex-direction: row;
}
.rr--dir-y {
  flex-direction: column;
}
.rr--space-sm .rr--svg {
  padding: 8%;
}
.rr--space-md .rr--svg {
  padding: 12.5%;
}
.rr--space-lg .rr--svg {
  padding: 17.5%;
}
.rr--dir-x.rr--gap-sm .rr--svg {
  margin: 0 6.25%;
}
.rr--dir-x.rr--gap-sm .rr--box:focus-visible:after {
  width: 87.5%;
  left: 6.25%;
}
.rr--dir-x.rr--gap-md .rr--svg {
  margin: 0 12.5%;
}
.rr--dir-x.rr--gap-md .rr--box:focus-visible:after {
  width: 75%;
  left: 12.5%;
}
.rr--dir-x.rr--gap-lg .rr--svg {
  margin: 0 25%;
}
.rr--dir-x.rr--gap-lg .rr--box:focus-visible:after {
  width: 50%;
  left: 25%;
}
.rr--dir-y.rr--gap-sm .rr--svg {
  margin: 6.25% 0;
}
.rr--dir-y.rr--gap-md .rr--svg {
  margin: 12.5% 0;
}
.rr--dir-y.rr--gap-lg .rr--svg {
  margin: 25% 0;
}
.rr--rx-sm .rr--svg {
  border-radius: 5%;
}
.rr--rx-md .rr--svg {
  border-radius: 15%;
}
.rr--rx-lg .rr--svg {
  border-radius: 20%;
}
.rr--rx-full .rr--svg {
  border-radius: 100%;
}
.rr--has-stroke .rr--svg {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.rr--has-border .rr--svg {
  border-width: var(--rr--border-width);
  border-style: solid;
}
.rr--on .rr--svg {
  fill: var(--rr--fill-on-color, none);
}
.rr--off .rr--svg {
  fill: var(--rr--fill-off-color, none);
}
.rr--has-stroke .rr--on .rr--svg {
  stroke: var(--rr--stroke-on-color, currentColor);
}
.rr--has-stroke .rr--off .rr--svg {
  stroke: var(--rr--stroke-off-color, currentColor);
}
.rr--on .rr--svg {
  background-color: var(--rr--box-on-color, none);
}
.rr--off .rr--svg {
  background-color: var(--rr--box-off-color, none);
}
.rr--has-border .rr--off .rr--svg {
  border-color: var(--rr--border-off-color, currentColor);
}
.rr--has-border .rr--on .rr--svg {
  border-color: var(--rr--border-on-color, currentColor);
}
.rr--fx-colors {
  --rr--easing: 0.2s cubic-bezier(0.61, 1, 0.88, 1);
}
.rr--fx-colors .rr--svg {
  transition-duration: 0.2s;
  transition-timing-function: var(--rr--easing);
  transition-property: background-color, border-color, fill, stroke;
}
.rr--fx-opacity .rr--off {
  opacity: 0.35;
  transition: opacity var(--rr--easing);
}
.rr--fx-opacity .rr--on {
  opacity: 1;
}
@media (hover: hover) {
  .rr--fx-opacity .rr--box:hover {
    opacity: 1;
  }
}
@media (hover: hover) {
  .rr--fx-zoom .rr--box {
    transition: transform var(--rr--easing);
    transform: scale(1);
  }
  .rr--fx-zoom .rr--box:hover {
    transform: scale(1.2);
  }
  @media (prefers-reduced-motion) {
    .rr--fx-zoom .rr--box:hover {
      transform: scale(1);
    }
  }
}
@media (hover: hover) {
  .rr--fx-position .rr--box {
    transition: transform var(--rr--easing);
    transform: translateY(0);
  }
  .rr--fx-position .rr--box:hover {
    transform: translateY(-15%);
  }
  @media (prefers-reduced-motion) {
    .rr--fx-position .rr--box:hover {
      transform: translateY(0);
    }
  }
}
.rr--svg-stop-1 {
  stop-color: var(--rr--fill-on-color, rgba(0, 0, 0, 0));
}
[dir="rtl"] .rr--svg-stop-1,
.rr--svg-stop-2 {
  stop-color: var(--rr--fill-off-color, rgba(0, 0, 0, 0));
}
[dir="rtl"] .rr--svg-stop-2 {
  stop-color: var(--rr--fill-on-color, rgba(0, 0, 0, 0));
}
.rr--hf-svg-on {
  fill: var(--rr--fill-on-color, none);
}
.rr--hf-svg-off {
  fill: var(--rr--fill-off-color, none);
}
.rr--has-stroke .rr--hf-svg-on {
  stroke: var(--rr--stroke-on-color, currentColor);
}
.rr--has-stroke .rr--hf-svg-off {
  stroke: var(--rr--stroke-off-color, currentColor);
}
.rr--hf-svg-on .rr--svg,
.rr--hf-svg-off .rr--svg {
  background-color: var(--rr--box-off-color, none);
}
.rr--has-border .rr--hf-svg-on .rr--svg {
  border-color: var(--rr--border-on-color, currentColor);
}
.rr--has-border .rr--hf-svg-off .rr--svg {
  border-color: var(--rr--border-off-color, currentColor);
}
.rr--dir-x .rr--hf-box-int .rr--svg {
  background: linear-gradient(
    to right,
    var(--rr--box-on-color, none) 50%,
    var(--rr--box-off-color, none) 50%
  );
}
[dir="rtl"] .rr--dir-x .rr--hf-box-int .rr--svg {
  background: linear-gradient(
    to left,
    var(--rr--box-on-color, none) 50%,
    var(--rr--box-off-color, none) 50%
  );
}
.rr--dir-y .rr--hf-box-int .rr--svg {
  background: linear-gradient(
    to bottom,
    var(--rr--box-on-color, none) 50%,
    var(--rr--box-off-color, none) 50%
  );
}
.rr--hf-box-on .rr--svg {
  background-color: var(--rr--box-on-color, none);
}
.rr--hf-box-off .rr--svg {
  background-color: var(--rr--box-off-color, none);
}
.rr--hf-box-on .rr--svg,
.rr--hf-box-off .rr--svg,
.rr--hf-box-int .rr--svg {
  fill: var(--rr--fill-off-color, none);
}
.rr--has-stroke .rr--hf-box-on .rr--svg,
.rr--has-stroke .rr--hf-box-off .rr--svg,
.rr--has-stroke .rr--hf-box-int .rr--svg {
  stroke: var(--rr--stroke-off-color, currentColor);
}
.rr--has-border .rr--hf-box-on .rr--svg,
.rr--has-border .rr--hf-box-int .rr--svg {
  border-color: var(--rr--border-on-color, currentColor);
}
.rr--has-border .rr--hf-box-off .rr--svg {
  border-color: var(--rr--border-off-color, currentColor);
}
