.preferences-container {
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff;
  padding: 20px;
}

.preferance-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.preferance-list {
  margin-top: 15px;
}

.preferance-item {
  position: relative;
  display: inline-block;
  padding: 9px 22px 9px 22px;
  border-radius: 17.5px;
  border: 1px;
  background-color: #f9f9f9;
  border: 1px solid #e9e9e9;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-right: 10px;
  margin-top: 10px;
}

.preferance-item-select {
  background-color: #EBF8ED;
  color: #0CA925;
  border: 1px solid #14BF59
}
