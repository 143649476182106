.user--management-opt {
  width: max-content;
  background-color: #fff;
}

.user--management-opt span {
  padding-left: 6px;
}

.user--management-opt ul {
  list-style-type: none;
  padding: 8px 8px 8px 14px;
}

.user--management-opt li span {
  color: var(--secondary-font-color);
  font-size: 12px;
  line-height: 14px;
}

.user--management-opt li {
  padding-right: 12px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
}

/* temp style */
.list-group {
  display: none;
}

.content-column:first-child .list-group {
  display: block;
}

/* modal styles => 'edit user'*/
.edit--user-modal .modal-dialog,
.edit--role-modal .modal-dialog {
  max-width: 1000px !important;
}

.edit--user-modal .modal-dialog .modal-footer {
  padding: 22px 64px;
}

.edit--user-modal .form-control,
.edit--modal-footer .btn-white {
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.edit--user-modal .modal-body,
.edit--user-modal .modal-header {
  padding-left: 64px;
  padding-right: 64px;
}

.edit--user-modal .modal-body form > div {
  margin-bottom: 24px;
}

.edit--user-modal .form-control {
  font-size: 14px;
  line-height: 14px;
}

.edit--user-modal .modal-body .form-select {
  background-size: 10px 5px;
}

.edit--modal-footer {
  background: #f9f9f9;
  padding: 22px 64px;
}

.edit--modal-footer .btn {
  padding: 8px 17px;
}

.edit--modal-footer .btn-white {
  color: var(--primary-font-color);
  margin: 0 16px;
  background-color: #fff;
}

.edit--modal-footer .btn-white:hover {
  background-color: #fff !important;
  opacity: 0.8;
}

.edit--modal-footer .modal-footer .btn-primary:hover {
  background-color: var(--primary-button-hover-color) !important;
  opacity: 0.8;
}

.edit--user-modal .form-label {
  font-weight: 500;
}

/* modal styles => 'suspended user'*/
.suspend--user-modal .modal-header span {
  margin-top: 16px;
  color: var(--primary-font-color);
  display: block;
}

.suspend--user-modal .edit--modal-footer {
  padding: 22px 32px;
}

/* .suspend--user-modal .modal-body, */
.suspend--user-modal .modal-header {
  padding: 16px 32px 50px 16px;
  justify-content: center;
  text-align: center;
}

.suspend--user-modal .modal-body {
  padding: 0;
}

.suspend--user-modal .modal-dialog {
  max-width: 401px;
}

.suspend--user-modal .modal-title {
  line-height: 150%;
}

.suspend--user-modal span,
.resetpass--user-modal label {
  font-weight: 500;
}

.resetpass--user-modal .modal-header {
  padding-bottom: 20px;
}

.resetpass--user-modal .modal-body {
  padding-bottom: 32px;
}

.resetpass--user-modal.modal-footer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
}

.resetpass--user-modal.modal-footer .btn {
  margin: 0;
}

.delete-message {
  color: var(--primary-font-color) !important;
}
