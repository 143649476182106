.user-articles-container {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.user-articles-title-text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #363636;
  line-height: 24px;
}

.article-category-container {
  width: 230px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #ffffff99;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  cursor: pointer;
}

.load-more-button {
  background: #fff !important;
  border: 1px solid #e9e9e9 !important;
  padding: 9px 17px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #000000 !important;
  line-height: 22px !important;
  width: 100%;
  height: 3.5rem;
  border-radius: 10px !important;
  margin-top: 20px;
  margin-bottom: 50px !important;
}

.load-more-button:hover {
  background-color: #f7f7f7 !important;
}

.load-more-button:active {
  background-color: #eeeeee !important;
}

@media screen and (max-width: 992px) {
  .article-search form {
    width: 100%;
  }
  .article-page-container {
    width: 95%;
  }
  .table-title {
    padding-left: 0px;
  }
  .table-container {
    width: 95%;
  }
}
