.new-role-modal .modal-footer {
  padding-left: 64px;
  padding-right: 64px;
}

.dropdown-heading {
  background-color: var(--modal-background-color);
  border: 1px solid #e9e9e9e9 !important ;
}

.dropdown-container {
  background-color: var(--modal-background-color) !important;
}

.rmsc {
  --rmsc-bg: var(--modal-background-color);
}
