.recent-card {
  background-color: var(--main-container-bg-color) !important;
  border: 1px solid #e9e9e9;
  width: 100%;
  min-height: 290px !important;
  border-radius: 20px !important;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #000;
}

.article-card {
  background-color: var(--main-container-bg-color) !important;
  border: 1px solid #e9e9e9;
  width: 100%;
  min-height: 490px;
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}

.package-card.new {
  background-color: #f3f6f4 !important;
  display: "flex";
  justify-content: "center";
  align-items: "Center";
  cursor: "pointer";
}

.article-recent-card-image {
  width: 100%;
  height: 160px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.article-card-image {
  width: 100%;
  height: 310px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.article-recent-title {
  font-size: 16px;
  font-weight: 800;
  color: var(--primary-font-color);
}

.article-recent-subtitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-font-color);
}

.article-title {
  font-size: 14px;
  font-weight: 800;
  color: var(--primary-font-color);
}

.article-description {
  color: #202020;

  size: 14px;
  margin-top: 8px;
}

.read-more-text {
  color: #14bf59;
  size: 14px;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.card-button {
  display: flex;
  border-radius: 100%;
  background-color: var(--main-background-color) !important;
  color: #979797 !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #9a9a9a;
  width: 16px;
  height: 16px;
  padding: 1px;
}

.package-actions-badge {
  position: absolute;
  margin-top: 5px;
  background-color: #00000092 !important;
  text-align: center;
  border-radius: 80%;
  color: #fbfbfb;
  padding: 7px 7px;
  font-size: 20px;
  margin-right: 5px;
}

.icon-edit {
  color: #afafaf;
  cursor: pointer;
}
.icon-delete {
  color: #afafaf;
  cursor: pointer;
}
.icon-delete:hover {
  color: #fee2e2;
}

.icon-edit:hover {
  color: #d1fae5;
}

@media screen and (max-width: 750px) {
  .package-card {
    width: 100%;
    font-size: 14px;
  }
  .package-title {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .package-subtitle {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-font-color);
  }
}

@media screen and (max-width: 490px) {
  .package-card {
    width: 100%;
    font-size: 14px;
  }
  .package-title {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .package-subtitle {
    font-size: 10px;
    font-weight: 500;
    color: var(--secondary-font-color);
  }
}
