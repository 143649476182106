.user--role-modal .modal-dialog {
  /* max-width: 488px; */
  max-width: 528px;
  width: 100%;
  margin-top: 40px;
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.user--role-modal .modal-title {
  font-size: 20px;
  line-height: 25px;
  color: var(--primary-font-color);
}

.user--role-modal .modal-dialog .modal-content {
  border: none;
  border-radius: 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  font-family: "Inter";
}

.invite--user-modal .modal-body label {
  font-weight: 500;
}

.modal-header {
  background: var(--modal-background-color);
  padding-top: 32px !important;
  color: var(--primary-font-color);
}

.user--role-modal .modal-body,
.user--role-modal .modal-header {
  padding: 16px 24px;
}

.user--role-modal.invite--user-modal .modal-body {
  padding-bottom: 40px;
}

.user--role-modal .modal-footer {
  padding: 22px 24px;
  background: var(--modal-footer-bg-color);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.user--role-modal .modal-title span,
.user--role-modal .modal-body select,
.user--role-modal .modal-body input[type="email"] {
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-font-color);
  font-weight: 400;
}

.user--role-modal .modal-dialog input[type="email"] {
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

.user--role-modal .modal-dialog .form-select .modal-dialog input[type="email"] {
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.user--role-modal .modal-body label {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #374151;
}

.user--role-modal .modal-body select,
.user--role-modal .modal-body input[type="email"] {
  color: var(--primary-font-color);
  margin-bottom: 24px;
}

.user--role-modal .modal-footer .btn-primary {
  background-color: var(--primary-button-color);
}

.user--role-modal .modal-footer .btn-secondary {
  color: #111827;
  border: 1px solid #d1d5db;
}

.user--role-modal .modal-footer .btn-secondary:active {
  color: #111827;
}

.user--role-modal .modal-footer .btn-secondary:hover {
  background-color: #fff !important;
}

.user--role-modal .modal-footer .btn-primary,
.user--role-modal .modal-footer .btn-secondary {
  padding: 9px 17px;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  transition: all 0.2s ease;
}

.user--role-modal .modal-footer .btn-primary:hover,
.user--role-modal .modal-footer .btn-secondary:hover {
  opacity: 0.8;
}

.user--role-modal .form-select:focus {
  box-shadow: none;
  border-color: #d1d5db;
}

.user--role-modal .modal-body .form-select {
  background-image: url("/public/role-dropdown-arrow.svg");
  background-size: 12.5px 6.25px;
}

.user--role-modal .modal-content .btn-close {
  display: none;
}

.user--role-modal .modal-body input[type="email"],
.user--role-modal .modal-body input[type="password"],
.user--role-modal .modal-body .form-select {
  height: 38px;
}

.user--role-modal .modal-body .form-control {
  padding: 9px 13px;
}
