.page-dropdown {
  background-image: url("/public/dropdown.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.page-dropdown.package-actions {
  background-image: url("/public/dropdown-white.svg");
  height: 28px;
  width: 28px;
  padding: 0px 0px 0px 0px;
}

.page-dropdown button {
  --bs-btn-color: none;
  --bs-btn-bg: none;
  --bs-btn-border-color: none !important;
  --bs-btn-hover-color: none !important;
  --bs-btn-hover-bg: none !important;
  --bs-btn-hover-border-color: none !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: none;
  --bs-btn-active-bg: none !important;
  --bs-btn-active-border-color: none !important;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: none !important;
  --bs-btn-disabled-bg: none !important;
  --bs-btn-disabled-border-color: none !important;
  background: none;
  box-shadow: none;
}

.page-dropdown .dropdown-toggle::after {
  border: none;
}
