.modal-view-style .modal-content {
  background-color: white;
  color: black;
}

.modal-view-style .modal-header {
  background-color: white;
  color: black;
  border-bottom: 1px solid #f1f1f1;
}

.modal-view-style .modal-body {
  background-color: white;
  color: black;
  border-bottom: 0px solid #f1f1f1;
}

.modal-view-style .modal-footer {
  background-color: white;
  color: black;
  border-top: 0px solid #f1f1f1;
}

.edit-guest-modal-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}

.edit-guest-modal-button {
  width: 111px;
  height: 40px;
  border-radius: 8px;
  color: #000000;
  background-color: #f2f2f2;
}

.edit-guest-modal-button:hover {
  color: #000000;
  background-color: #f2f2f2;
}

.edit-guest-modal-button-save {
  width: 111px;
  height: 40px;
  border-radius: 8px;
  background-color: #14bf59;
  color: #ffffff;
}

.edit-guest-modal-button-save:hover {
  color: #ffffff;
  background-color: #14bf59;
}

.calendar-picker-style {
  background-color: #f9f9f9;
}

.edit-guest-date-picker-style {
  display: block;
  width: 100%;
}
.add-reaservation-modal-title {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}
.add-reaservation-input-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 10px;
  width: 100%;
  height: 216px;
  border: 1px solid #e9e9e9;
  padding-left: 13px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 8px;
  margin-bottom: 20px;
}
.category-name-text-view {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}
