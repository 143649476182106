.invite-user {
  background-color: var(--primary-button-color);
  border-radius: 6px;
  color: #fff;
  border: none;
  padding: 8px 17px 8px 32px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  font-size: 12px;
  line-height: 16px;
}

.invite-user:hover {
  background-color: var(--primary-button-hover-color) !important;
}

.invite-user:active {
  background-color: var(--primary-button-active-color) !important;
}

.invite-user::before {
  position: absolute;
  content: "";
  left: 16px;
  top: 12px;
  background: url("/public/plus-icon.svg") no-repeat center;
  width: 8px;
  height: 8px;
}

.invite-user:hover {
  opacity: 0.9;
}

.invite-user .user--option-img {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}
