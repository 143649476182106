.detail-section {
  padding-right: 0px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-font-color);
}
.detaile-main-container-info {
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
}
.detaile-main-container {
  width: 94%;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 20px;
}
.art-and-culture-tect-style {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #7a7a7a;
}
.three-dot-and-culture-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.art-and-culture-main-text {
  font-family: Inter;
  font-size: 32px;
  font-weight: 600;
  line-height: 43px;
  text-align: left;
  color: #000000;
  max-width: 85%;
}
.image-text-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.dot-style {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #d9d9d9;
  margin-left: 20px;
}
.tag-arry-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}
.tag-text-view {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #3c3c3c;
  background-color: #f2f2f2;
  height: 38px;
  padding: 10px 20px 10px 20px;
  border-radius: 56px;
  margin-right: 10px;
}
.time-reading-view {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  opacity: 0.5;
  margin-left: 10px;
}
.tags-text-style {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #000000;
}
.date-and-time-text-style {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #000000;
  margin-left: 10px;
  opacity: 0.7;
  margin-top: 10px;
}
.art-and-clture-info {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  opacity: 0.8;
  color: #202020;
  margin-top: 15px;
}
.dot-and-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}
.date-and-readimg-time {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.name-text-style {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #000000;
  margin-left: 10px;
}
.art-and-clture-name-view {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.detail-title {
  padding-left: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-font-color);
}

.detail-header-section {
  color: var(--primary-font-color);
  margin-top: 32px;
}

.article-body {
  padding: 24px 32px;
  margin-top: 12px;
}

.article-detail-title {
  margin-bottom: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: var(--primary-font-color);
  line-height: 150%;
}

.article-detail-hash {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--secondary-font-color);
  line-height: 150%;
}

.article-detail-tags {
  margin-top: 48px;
}

.article-detail-tag-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--primary-font-color);
}

.article-detail-tag {
  background: #ede9fe;
  border-radius: 10px;
  padding: 2px 10px;
  color: #5b21b6;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-right: 8px;
  line-height: 16px;
}

.article-detail-content {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: 18px;
  min-height: 300px;
}

.article-detail-container {
  background-color: white;
  width: 100%;
  height: 100vh;
}

.article-detail-images {
  margin-top: 48px;
}

.article-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0px 20px 0px;
}

.artical-more-image-container {
  width: 49.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.article-more-row-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.article-more-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.article-last-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  filter: brightness(0.7) blur(2px);
  object-fit: cover;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: bold;
  pointer-events: none;
}

.article-main-image {
  width: 100%;
  height: 400px;
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .article-detail-title {
    font-size: 22px;
  }
}
