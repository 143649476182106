.search-result-card {
  position: absolute;
  top: 150%;
  margin-top: var(--bs-dropdown-spacer);
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 16%);
  border-radius: 6px;
  padding: 16px 24px;
  z-index: 2;
}

.search-content {
  margin-top: 22px;
}

.search-result-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #6b7280;
  letter-spacing: 0.05em;
}

.search-result-item {
  margin-top: 10px;
}

.search-result-item-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #111827;
  cursor: pointer;
}

.search-result-item-section {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #6b7280;
}

@media screen and (max-width: 992px) {
  .search-result-card {
    margin: auto;
    top: 110%;
  }
}
