.delete-button {
  background: #dc2626 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 6px !important;
  border: none !important;
  padding: 9px 17px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--primary-button-font-color) !important;
  font-family: "Inter" !important;
}
