.login {
  min-height: 100vh;
  height: 100%;
  background-color: white;
}

.loginHeader {
  margin-bottom: 32px;
}

h3.login-heading {
  color: #202020;
  margin-bottom: 0;
}

.loginLogo {
  margin-bottom: 25.5px;
}

.loginSeparator {
  margin-bottom: 2%;
  flex-wrap: nowrap;
}

.line-2 {
  transform: rotate(0deg);
  height: 0px;
  width: 100%;
  border-top: 1px solid #e9e8ee;
}

.line-1 {
  transform: rotate(0deg);
  height: 0px;
  width: 100%;
  border-top: 1px solid #e9e8ee;
  background-color: #4a3ff3 !important;
}

.text-9 {
  text-align: left;
  vertical-align: top;
  font-size: 16px;
  font-family: Inter;
  line-height: 24%;
  color: #81868c;
}

.font-awesome-google {
  height: 24px;
  width: 24px;
  background-color: #ffffff;
}

.login-heading {
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #202020;
}

.loginSeparator,
.login-form {
  width: 100%;
  margin: 0 auto;
}

.login .container-fluid {
  max-width: 350px;
}

.login-form .email-password {
  width: 100%;
  position: relative;
}

.login-form input[type="email"] {
  border: 1px solid #E9E9E9 !important;
  border-radius: 6px;
}

.login-form input[type="password"] {
  border-radius:  6px;
}

.checkbox-forgotpass {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.login-form-email {
  width: 100%;
  height: 42px;
  border: 1px solid #E9E9E9 !important;
  padding-left: 13px;
  border-radius: 6px;
  background-color: #ffffff !important;
  color: #202020 !important;
}

.login-form-email::placeholder {
  color: rgba(32, 32, 32, 0.3);
}

.eye-icon-container {
  cursor: pointer;
}

.login-form-password {
  width: 100%;
  height: 42px;
  border: 1px solid #E9E9E9 !important;
  padding-left: 13px;
  border-radius: 6px;
  margin-top: 20px;
  background-color: #ffffff !important;
  color: #202020 !important;
}

.login-form-password::placeholder {
  color: rgba(32, 32, 32, 0.3);
}

.eye-icon {
  position: absolute;
  right: 4%;
  bottom: 13px;
  z-index: 100px;
}

.submit-btn {
  background: #14BF59;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 33px;
  width: 100%;
  transition: all 0.2s ease;
  font-size: 14px;
  line-height: 20px;
  height: 38px;
}

.calcel-btn {
  background: #ffffff;
  color: var(--primary-button-color);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px;
  margin-top: 24px;
  margin-bottom: 33px;
  width: 100%;
  transition: all 0.2s ease;
  font-size: 14px;
  line-height: 20px;
  height: 38px;
}

.submit-btn:hover {
  background: var(--primary-button-hover-color);
  color: #ffffff;
}

.forgot-password {
  text-decoration: none;
  color: #15CF5F;
}

.form-check-label {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #202020;
}

.forgot-password {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
}

.form-check-input[type="checkbox"] {
  border: 1px solid var(--secondary-font-color);
}

label {
  color: var(--secondary-font-color);
}

.form-control:focus {
  box-shadow: none;
}

.google-login-opt {
  margin-top: 14px;
}

.google--login-btn {
  width: 100%;
  border: 1px solid #d1d5db;
  margin: 0 auto;
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #ffffff;
  color: #6b7280;
}

.google--login-btn img {
  margin-right: 13.8px;
}

.qJTHM {
  background-color: var(--primary-button-color) !important;
}

.qJTHM {
  background-color: var(--primary-button-color) !important;
}

.qJTHM {
  background-color: var(--primary-button-color) !important;
}

.qJTHM {
  background-color: var(--primary-button-color) !important;
}

iframe .haAclf {
  width: 100% !important;
}

@media (max-width: 768px) {
  .login {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (max-width: 400px) {
  .line-1,
  .line-2 {
    display: none;
  }

  .loginSeparator .col-sm-4 {
    display: none;
  }

  .loginSeparator .col-sm-auto {
    width: 100%;
  }

  .loginSeparator .text-9 {
    text-align: center;
  }
}
