.sun-editor .se-btn-tray {
  background: white;
}

.sun-editor .se-toolbar {
  padding: 2px;
}

.pb-24px {
  padding-bottom: 24px;
}

.border-bottom {
  border-bottom: 1px solid #f7f7f7 !important;
}

.bed-add-button {
  background-color: white !important;
  color: #4f46e5 !important;
  box-shadow: none;
}

.bed-add-button img {
  width: 16px;
  height: 16px;
}

.bed-add-button:hover {
  background-color: var(--bs-btn-hover-border-color) !important;
}

.bed-remove-button {
  background-color: white !important;
  color: #991b1b !important;
  box-shadow: none;
}

.bed-remove-button img {
  width: 16px;
  height: 16px;
}

.bed-remove-button:hover {
  background-color: var(--bs-btn-hover-border-color) !important;
}

.multi-select-border {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem;
  padding: 1px;
}
