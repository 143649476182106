.attachment-item {
  width: 86px;
  height: 80px;
  padding: 0px;
  margin-top: 14px;
  border-radius: 6px;
  margin-right: 15px;
  position: relative;
}

.attachment-img {
  width: 100%;
  border-radius: 6px;
  max-height: 80px;
  min-height: 80px;
  max-width: 86px;
  min-width: 86px;
}

.attachment-delete {
  left: 60px;
  top: -10px;
  background: #e9e9e9;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  padding: 1px;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.attachment-delete-icon {
  width: 16px;
  height: 16px;
}
