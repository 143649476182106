.modal-view-style .modal-content {
  background-color: white;
  color: black;
}

.modal-view-style .modal-header {
  background-color: white;
  color: black;
  border-bottom: 1px solid #f1f1f1;
}

.modal-content-style {
  width: 720px;
}

.article-modal-classname {
  max-width: 720px !important;
}

.modal-view-style .modal-body {
  background-color: white;
  color: black;
  border-bottom: 0px solid #f1f1f1;
}

.modal-view-style .modal-footer {
  background-color: white;
  color: black;
  border-top: 0px solid #f1f1f1;
}

.edit-guest-modal-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}

.edit-guest-modal-button {
  width: 111px;
  height: 40px;
  border-radius: 8px;
  color: #000000;
  background-color: #f2f2f2;
}

.edit-guest-modal-button:hover {
  color: #000000;
  background-color: #f2f2f2;
}

.edit-guest-modal-button-save {
  width: 111px;
  height: 40px;
  border-radius: 8px;
  background-color: #14bf59;
  color: #ffffff;
}

.edit-guest-modal-button-save:hover {
  color: #ffffff;
  background-color: #14bf59;
}

.calendar-picker-style {
  background-color: #f9f9f9;
}

.add-reaservation-modal-title {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #202020;
}
.add-article-input-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 10px;
  width: 100%;
  border: 1px solid #e9e9e9;
  padding-left: 13px;
  border-radius: 6px 6px 6px 6px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.ql-formats {
  margin-right: 0px !important;
}

.ql-container {
  border: 0px !important;
  min-height: 110px;
}

.ql-toolbar {
  border: 0px !important;
  border-bottom: 1px solid #f0f1f280 !important;
}

.quill {
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 20px;
}

.sc-bcXHqe {
  width: 100% !important;
}

.media-file-upload-container {
  width: 100%;
  height: 150px;
  padding: 12px 15px 12px 15px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #f9f9f9;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ql-editor.ql-blank::before {
  color: #a4a4a4 !important;
  opacity: 0.7;
  font-style: normal !important;
}

.ql-bold {
  margin-right: 10px;
}

.ql-italic {
  margin-right: 10px;
}

.ql-underline {
  margin-right: 10px;
}

.ql-strike {
  margin-right: 10px;
}

.ql-list {
  margin-right: 10px;
}

.ql-align {
  margin-right: 10px;
}

.ql-bold {
  margin-right: 10px;
}

.file-upload-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222222;
  margin-top: 15px;
}

.file-upload-desc {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #222222;
  margin-top: 5px;
}

.add-tags-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  min-width: 114px;
  padding: 10px;
  border-radius: 56px;
  margin-right: 10px;
  color: #15cf5f;
  background-color: #ebf7f0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.edit-tags-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 56px;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #5786ff;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 5px;
}

.view-tags-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 56px;
  padding: 8px;
  margin-right: 10px;
  background-color: #f2f2f2;
  color: #232323;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-top: 5px;
}

.add-tag-icon {
  margin-right: 6px;
  position: relative;
  display: inline-block;
}

.delete-tag-icon {
  margin-left: 6px;
  position: relative;
  display: inline-block;
}

.tag-list-style {
  margin-top: 5px;
}

.add-tag-arry-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.edit-tag-input {
  border: 0px;
  width: 68px;
}

.add-article-img {
  width: 90px;
  height: 90px;
  border-radius: 6px;
}

.add-article-img-container {
  width: 90px;
  padding-top: 10px;
  border-radius: 6px;
  margin-right: 15px;
  position: relative;
}

.delete-artical-img {
  position: absolute;
  top: 0px;
  cursor: pointer;
  right: 5px;
  background-color: #f9f9f9;
  border-radius: 16px;
}
