.accept-invite-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-font-color);
}

.accept-invite {
  background: var(--main-container-bg-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.accept-button {
  background: var(--primary-button-color);
  border: 1px solid #4f46e5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  line-height: 22px;
}

.accept-button:active {
  background: var(--primary-button-active-color) !important;
}

.accept-cancel-button {
  background: #ffffff;
  border: 1px solid #4f46e5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 9px 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.accept-cancel-button:hover {
  background: var(--secondary-button-active-color) !important;
}

.accept-form div {
  margin-top: 15px;
}

.accept-form .form-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
  margin-bottom: 4px;
}
