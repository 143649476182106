.permission-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #202020;
  margin-top: 30px;
  margin-bottom: 10px;
}
.swich-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}
.border-bottom-view{
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #F1F1F1
}
.swich-text-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
}
.custom-switch .form-check-input {
  width: 29px; 
  height: 18px; 
  box-shadow: none;
}

.custom-switch .form-check-input::before {
  width: 25px; 
  height: 25px; 
  box-shadow: none; 
}

.custom-switch .form-check-input:checked::before {
  transform: translateX(16px);
}

.custom-switch .form-check-input:checked {
  background-color: #0d6efd;
}

