.icon-card {
  background-color: var(--main-background-color) !important;
  border: 1px solid #515151 !important;
  width: 100%;
  height: 5.3rem;
  border-radius: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.card-title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-font-color);
}

.card-button {
  display: flex;
  border-radius: 100%;
  background-color: var(--main-background-color) !important;
  color: #979797 !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #9a9a9a;
  width: 16px;
  height: 16px;
  padding: 1px;
}

@media screen and (max-width: 750px) {
  .icon-card {
    width: 100%;
  }
}

@media screen and (max-width: 490px) {
  .card-title {
    font-size: 14px;
  }
}
