.package-card {
  background-color: var(--main-container-bg-color) !important;

  border: none;
  width: 100%;
  height: 200px;
  border-radius: 5px;

  margin-top: 20px;
  margin-bottom: 20px;
  color: #000;
}
.package-card.new {
  background-color: #f3f6f4 !important;
  display: "flex";
  justify-content: "center";
  align-items: "Center";
  cursor: "pointer";
}

.package-card-image {
  width: 100%;
  height: 135px;
}

.package-title {
  font-size: 14px;
  font-weight: 800;
  color: var(--primary-font-color);
}
.package-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-font-color);
}

.card-button {
  display: flex;
  border-radius: 100%;
  background-color: var(--main-background-color) !important;
  color: #979797 !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #9a9a9a;
  width: 16px;
  height: 16px;
  padding: 1px;
}

.package-actions-badge {
  position: absolute;
  margin-top: 5px;
  background-color: #00000092 !important;
  text-align: center;
  border-radius: 80%;
  color: #fbfbfb;
  padding: 7px 7px;
  font-size: 20px;
  margin-right: 5px;
}

.icon-edit {
  color: #afafaf;
  cursor: pointer;
}
.icon-delete {
  color: #afafaf;
  cursor: pointer;
}
.icon-delete:hover {
  color: #fee2e2;
}

.icon-edit:hover {
  color: #d1fae5;
}

@media screen and (max-width: 750px) {
  .package-card {
    width: 100%;
    font-size: 14px;
  }
  .package-title {
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .package-subtitle {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-font-color);
  }
}

@media screen and (max-width: 490px) {
  .package-card {
    width: 100%;
    font-size: 14px;
  }
  .package-title {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .package-subtitle {
    font-size: 10px;
    font-weight: 500;
    color: var(--secondary-font-color);
  }
}
