.create-password {
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #d1d5db;
  width: 100%;
}
.create-password input:nth-child(2) {
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #d1d5db !important;
}
