.admin-article-table {
  margin-top: 56px;
}

.admin-article-edit {
  margin-top: 24px;
}

.admin-article-edit .main-container {
  width: 100%;
  padding: 0px;
}

.admin-article-table-search {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--secondary-font-color);
  margin-bottom: 4px;
  padding: 16px 16px 0px 16px;
}

.search-title-label {
  margin-top: 8px;
  margin-bottom: 4px;
}

.table-title-section {
  padding: 16px 16px 0px 16px;
}

.table-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: var(--primary-font-color);
  margin-bottom: 0px;
}

.table-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-font-color);
}

.table-sub-title .bold {
  color: var(--primary-font-color);
}

.admin-table-section {
  margin-top: 8px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 6%);
  border-radius: 16px;
  padding: 0px;
}
