.admin-tabs {
  margin-top: 24px;
  --bs-nav-tabs-border-color: var(--main-background-color);
  --bs-nav-tabs-border-width: 1px;
}
.admin-tab {
  --bs-nav-tabs-border-width: none;
}

.nav-item {
  --bs-nav-tabs-border-width: none;
}

.admin-tabs .nav-link {
  padding: 11px 16px !important;
}

.nav-tabs .nav-link {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-font-color);
}

.navbar-nav .nav-link.active,
.navbar-nav .show > .nav-link {
  color: var(--primary-font-color) !important;
}

.nav-tabs .nav-link.active {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-button-color) !important;
  border-bottom: solid 2px var(--primary-button-color) !important;
  background-color: var(--main-container-bg-color) !important;
}
