.form-label {
  padding: 0px;
  color: var(--primary-font-color) !important;
  padding-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}

.form-value {
  padding: 0px;
  color: var(--primary-font-color) !important;
  padding-bottom: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0px;
}

.modal {
  --bs-modal-border-width: 0px !important;
}

.details-container {
  width: 80%;
  margin-top: 62px;
  border-radius: 16px;
}

.current-stay-text {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: 25px;
  margin-bottom: 14px;
  color: #202020;
  display: flex;
  align-items: center;
}
.edit-container {
  width: 40px;
  height: 40px;
  border-radius: 68px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.edit-container {
  width: 40px;
  height: 40px;
  border-radius: 68px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stay-reservation-container {
  margin-left: 25px;
  margin-right: 0px;
}

.profile-reservation-container {
  width: 97%;
  border-radius: 10px;
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  position: relative;
  margin-top: 60px;
  padding-top: 40px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.price-reservation-container {
  width: 97%;
  border-radius: 10px;
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  position: relative;
  margin-top: 20px;
  padding: 20px;
}

.name-initial-container {
  width: 80px;
  height: 80px;
  top: 40px;
  left: 128px;
  background-color: #f1f1f1;
  border: 1px solid #e9e9e9;
  border-width: 1px;
  border-radius: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  letter-spacing: 0.06em;
  color: #535353;
}
.profile-name-text {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;
}
.gmail-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.user-details-item {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 10px;
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-detail-item-image {
  width: 44px;
  height: 44px;
  background-color: #222222;
  border-radius: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-detail-info-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #747474;
}
.user-detail-info-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-top: 8px;
}
.btn-container {
  width: 90%;
  height: 44px;
  top: 499px;
  left: 20px;
  border-radius: 8px;
  background-color: #14bf59;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.user-detail-info-container {
  margin-left: 15px;
}

.guest-message-btn-text {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-left: 8px;
  cursor: pointer;
}

.main-reservation-detail-container {
  padding: 0;
  margin: 0px 0px 45px 0px;
}

.price-text-style {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #202020;
  margin-bottom: 10px;
}

.price-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 15px;
}

.price-info-title {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  color: #000000;
  display: flex;
  flex-direction: row;
}

.price-info-value {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}

.price-promo-text {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #000000;
  margin-left: 3px;
}

.price-info-divider {
  margin-top: 36px;
  height: 1px;
  width: 100%;
  background-color: #000000;
}

.price-info-total {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  color: #202020;
}

.price-info-total-value {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #000000;
}

.stay-list-info-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
}

.purchase-package-table-container {
  margin-left: 25px;
}

.add-package-report-icon {
  margin-left: 5px;
  cursor: pointer;
}

.wrap-description-table-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 144px;
}

.table-cell-text-report-name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  letter-spacing: -0.02em;
  color: #202020;
}

.report-table-menu-conatiner {
  cursor: pointer;
  width: 20px;
}

.status-container {
  width: 85px;
  height: 24px;
  padding: 6px 12px 6px 12px;
  border-radius: 36px;
  background-color: #eaeffb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.report-status-text {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: center;
  color: #2651c1;
  margin-right: 3px;
}
.status-header-text {
  margin-left: 15px;
}
.previous-stay-item-container-main {
  background-color: #fbfbfb;
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
  border-radius: 10px 10px 10px 10px;
  padding: 20px;
}

.previous-stay-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.new-add-on-package-view {
  border-radius: 12px;
  background: linear-gradient(269.4deg, rgba(203, 222, 87, 0.74) 0.66%, #59b980 48.83%);
  width: calc(100% - 55px);
  margin-left: 30px;
  margin-right: 25px;
  margin-top: 25px;
  padding: 14px 20px 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-on-package-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-on-package-text-container {
  margin-left: 12px;
}

.add-on-package-text {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
}

.add-on-package-inof-text {
  margin-top: 5px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.31px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
}

.add-package-learn-more {
  border-radius: 8px;
  padding: 6px 22px 6px 22px;
  background-color: #f9f9f9;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000000;
  cursor: pointer;
}

@media screen and (max-width: 750px) {
  .details-container {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
.preference-title-container {
  margin-left: 6px;
  cursor: pointer;
}

.status-indicator-small {
  width: 8px;
  height: 8px;
  border-radius: 8px;
}

.status-drop-down-padding {
  padding: 6px 0px 6px 0px;
}

.status-drop-down-small-container {
  display: flex;
  flex-direction: row;
  padding: 6px 12px 6px 12px;
  align-items: center;
  cursor: pointer;
}
.status-drop-down-small-container:hover {
  background-color: #e9e9e9 !important;
}

.edit-damage-report-container {
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  cursor: pointer;
}
.edit-icon-image-style {
  width: 18px;
  height: 18px;
}
.delete-text-style {
  color: #db453c;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  margin-left: 10px;
}
.edit-text-style {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000000;
  margin-left: 10px;
}
