.dropdown-title {
  padding-right: 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-font-color);
}

div:has(> .select-dropdown) {
  width: auto !important;
}

.select-dropdown {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-font-color);
  background: var(--text-input-bg-color);
  border-radius: 6px !important;
  min-height: 42px !important;
  min-width: 224px;
  border: none !important;
}

.select-dropdown:focus,
.select-dropdown:focus-within {
  box-shadow: none !important;
  border-radius: 6px !important;
}

.react-dropdown-select-dropdown {
  width: 224px !important;
  max-height: none !important;
  background: var(--modal-background-color) !important;
  color: var(--secondary-font-color);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14) !important;
  border-radius: 6px !important;
  border: none !important;
}

.react-dropdown-select-dropdown {
  padding: 6px !important;
  padding-bottom: 17px !important;
  overflow: hidden !important;
}

.react-dropdown-select-option {
  background: #ede9fe !important;
  border-radius: 15px !important;
  padding: 0px 10px 0px 6px !important;
  color: #5b21b6 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  flex-direction: row-reverse !important;
  height: 22px;
  align-items: center;
}

.react-dropdown-select-option .react-dropdown-select-option-remove {
  color: #000000;
  margin: 0px 2px 3px 2px !important;
  font-size: 20px;
}

.article-row {
  margin-bottom: 16px;
}

.filter-dropdown {
  padding-left: 0px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.filter-dropdown {
  padding-left: 0px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 32px;
}

.react-dropdown-select-content input {
  color: var(--secondary-font-color);
  line-height: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 14px;
}

.select-dropdown-item {
  padding: 9px 17px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--secondary-font-color);
}

.select-dropdown-item:hover {
  background-color: var(--dropdown-item-hover);
}

.select-dropdown-item:active {
  background-color: var(--dropdown-item-active);
}

.dropdown-cancel {
  background: var(--secondary-button-color);
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 7px 11px;
}

.dropdown-apply {
  background: var(--primary-button-color);
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  padding: 7px 11px;
}

.dropdown-buttons {
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 16px;
  border-top: 1px solid #f0f2f5;
}
